import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Experience from "components/experience"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "about" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              lhp
              lhs
              intro
              bio
              bio_text
              work {
                blurbs {
                  content
                  description
                  title
                }
              }
              skill {
                heading
                heading_text
                blurbs {
                  content
                  description
                  title
                }
              }
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "potrait.jpg" }) {
      childImageSharp {
        fixed(width: 250, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Image = data => {
  return (
    <Img
      fixed={data.placeholderImage.childImageSharp.fixed}
      alt="Nwachukwu Chibuike"
      className="bio-image"
    />
  )
}

const AboutPage = props => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <div className="body">
      <Layout link="about">
        <SEO
          title="About Me"
          description="Hi, I am Nwachukwu chibuike, an avid learner, programmer, web developer and enterpreneur. I love doing great work with my pc and always love to bring in organisation and uniqueness to every project."
          keywords="Nwachukwu chibuike, Chibuike, Nwachukwu, Web developer nigeria, VueJs, Lagos, FUTO"
        />
        <main id="about">
          <h1 className="lg-heading">
            {data.lhp}&nbsp;
            <span className="text-secondary">{data.lhs}</span>
          </h1>
          <h2 className="sm-heading">{data.intro}</h2>
          <div className="about-info">
            <div>{Image(props.data)}</div>
            <div className="bio">
              <h3 className="text-secondary"> {data.bio}</h3>
              <p>{data.bio_text}</p>
            </div>
            <Experience gridItems={data.work.blurbs} />
          </div>
          <br />
          <div className="about-info">
            <div></div>
            <div className="bio">
              <h3 className="text-secondary"> {data.skill.heading}</h3>
              <p>{data.skill.heading_text}</p>
            </div>
            <Experience gridItems={data.skill.blurbs} />
          </div>
        </main>
      </Layout>
      <Footer />
    </div>
  )
}

export default AboutPage
