import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

const createMarkup = data => {
  return { __html: data }
}
const Experience = ({ gridItems }) =>
  gridItems[0].node
    ? gridItems.map((item, index) => (
        <div className="job " key={index}>
          <h3>{item.node.frontmatter.title}</h3>
          <h5>{item.node.frontmatter.description}</h5>
          <p>
            <Link className="btn" to={item.node.fields.slug}>
              {" "}
              Read more{" "}
            </Link>
          </p>
        </div>
      ))
    : gridItems.map((item, index) => (
        <div className="job " key={index}>
          <h3>{item.title}</h3>
          <h6>{item.description}</h6>
          <div dangerouslySetInnerHTML={createMarkup(item.content)} />
        </div>
      ))

Experience.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

export default Experience
